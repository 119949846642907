<template>
  <div class="my-4 text-center">
    <img
      src="/images/no-found-category.svg"
      alt="no-found"
      class="no-found-svg"
    />
    <h4 class="mt-5 text-muted">
      {{ text }}
    </h4>
    <div class="mt-4" v-if="!noButton">
      <router-link :to="link">
        <button class="btn btn-pill donate">
          {{ buttonText }}
        </button></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: function () {
        return this.$t("noFoundData");
      },
    },
    buttonText: {
      type: String,
      default: function () {
        return this.$t("goHome");
      },
    },
    link: {
      default: "/",
    },
    noButton: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style></style>
