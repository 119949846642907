<template>
  <div class="container mt-4">
    <!-- <div class="my-4">
      <button @click="$emit('back')" class="btn btn-pill subscribe">
        {{ $t("goEventList") }}
      </button>
    </div> -->
    <h2 class="mt-4">
      {{ $t("users") }}
      <span class="text-muted">{{ count }}</span>
    </h2>

    <div class="d-flex justify-content-end">
      <button
        class="btn btn-pill donate"
        @click="
          modalKey += 1;
          showAddUserModal = true;
        "
      >
        + {{ $t("addProductToUser") }}
      </button>
    </div>
    <div
      v-if="selectedAllItemWithoutPage || checkedList.length !== 0"
      class="my-4 table-header text-center align-items-center d-flex w-100 justify-content-center"
    >
      <span
        @click="GET_ALL_USER_EMAIL"
        v-if="selectedAllItemWithoutPage"
        class="tel-link"
      >
        ({{ count }}) {{ $t("sendMail") }}
      </span>
      <span
        @click="
          $store.commit('SET_MAIL_DRAWER', {
            status: true,
            form: {
              to: '',
              bcc: checkedList.map((c) => c.email).join(','),
              subject: $t('aboutBoughtProduct', {
                typeTitle: product.type.title,
                productTitle: product.title,
              }),
              content: '',
              files: [],
            },
          })
        "
        v-else-if="checkedList.length !== 0"
        class="tel-link"
      >
        ({{ checkedList.length }}) Kullanıcıya Mail Gönder
      </span>
    </div>
    <Tables
      @change:search="SET_SEARCH"
      :loading="usersAreLoading"
      :items="userList"
      :count="count"
      @change:selectAllWithoutPage="(e) => (selectedAllItemWithoutPage = e)"
      @change:checkedList="(e) => (checkedList = e)"
      checkable
      :headers="headers"
    >
      <template #fullname="{ item }">
        <div>
          <span>
            <a class="email-link" :href="`mailto:${item.email}`">{{
              item.email
            }}</a></span
          >
        </div>
        <div>
          <span>
            <a class="tel-link" :href="`tel:${item.phone}`">{{
              item.phone
            }}</a></span
          >
        </div>
      </template>
      <template #orderNo="{ item }">
        {{ item.orderNo }}
        <div>
          <strong>
            <router-link class="tel-link" :to="`/user/${item.id}`">
              <i class="icon-user"></i> {{ item.fullname }}</router-link
            >
          </strong>
        </div>
      </template>
      <template #purchasedAt="{ item }">
        {{ formatter(new Date(item.purchasedAt + "+00:00")) }}
      </template>
      <template #status="{ item }">
        <span
          v-if="product.type.type !== 3"
          class="badge badge-success"
          :class="{
            'badge-success': item.status === 2,
            'badge-danger': item.status === 0,
            'badge-warning': item.status === 1,
            'badge-warning': item.status === 3,
            'badge-info': item.status === 4,
          }"
        >
          {{
            item.status === 1
              ? $t("waiting")
              : item.status === 2
              ? $t("done")
              : item.status === 0
              ? $t("canceled")
              : item.status === 3
              ? $t("offerWaiting")
              : item.status === 4
              ? $t("offered")
              : ""
          }}
        </span>
        <div class="mt-2" v-if="getSettings.departmentId !== 2">
          <strong v-if="item.status === 3 || item.status === 4">
            {{
              Currency(
                { price: item.price, discount: item.discount },
                item.currency || "TRY"
              )
            }}
          </strong>
          <strong v-else>
            {{ Currency({ price: item.price }, item.currency || "TRY") }}
          </strong>
          <small v-if="item.discount > 0 && item.promotion === null"
            >(%{{ item.discount }})</small
          >
          <div v-if="item.promotion !== null" class="text-success">
            <small
              >{{ item.promotion.code }} (-{{
                item.promotion.discount
              }}%)</small
            >
          </div>
        </div>
      </template>
      <template #action="{ item }">
        <div class="text-right">
          <button
            v-if="product.type.type !== 3"
            class="btn btn-pill btn-xs mr-2"
            @click="
              activeUserUpdate = item;
              showActiveUserUpdateModal = true;
            "
          >
            {{ $t("view") }}
          </button>
          <button
            @click="
              $store.commit('SET_MAIL_DRAWER', {
                status: true,
                form: {
                  to: item.email,
                  bcc: '',
                  subject: $t('aboutBoughtProduct', {
                    typeTitle: product.type.title,
                    productTitle: product.title,
                  }),
                  content: '',
                  files: [],
                },
              })
            "
            class="btn btn-pill donate btn-xs"
          >
            {{ $t("sendMail") }}
          </button>
        </div>
      </template>
    </Tables>
    <UserListOfEventInsert
      :key="modalKey"
      :product="{ ...product, id: event.id }"
      @refresh="GET_LIST"
      v-model="showAddUserModal"
    ></UserListOfEventInsert>
    <UserListOfEventUpdate
      :activeUserUpdate="activeUserUpdate"
      :product="{ ...product, id: event.id }"
      @refresh="GET_LIST"
      :event="event"
      v-model="showActiveUserUpdateModal"
    >
      <template #event-date>
        <p>
          <strong>{{ $t("eventDate") }}</strong
          >:
          {{
            formatter(new Date(event.date), {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })
          }}
        </p>
        <p>
          <strong>{{ $t("eventDuration") }}</strong
          >:
          {{ convertM2H(event.duration) }}
        </p>
      </template>
      <template #purchase-date>
        <h5 class="my-4 text-center">
          <strong>{{ $t("createdAt") }}</strong
          >:
          {{ formatter(new Date(activeUserUpdate.purchasedAt)) }}
        </h5>
      </template>
    </UserListOfEventUpdate>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
  </div>
</template>

<script>
import { formatter, convertM2H } from "gurhan/helpers/Date";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";
import Tables from "../Tables/index.vue";
import UserListOfEventUpdate from "./UserListOfEventUpdate.vue";
import UserListOfEventInsert from "./UserListOfEventInsert.vue";
import { Currency } from "gurhan/helpers/Currency";
export default {
  components: {
    Tables,
    BoostrapPagination,
    UserListOfEventUpdate,
    UserListOfEventInsert,
  },
  data() {
    return {
      modalKey: 1,
      usersAreLoading: false,
      showAddUserModal: false,
      checkedList: [],
      userList: [],
      activeUserUpdate: { id: -1 },
      showActiveUserUpdateModal: false,
      selectedAllItemWithoutPage: false,
      search: "",
      page: 1,
      limit: 10,
      count: 0,
      headers: [
        {
          title: "No",
          value: "orderNo",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("user"),
          value: "fullname",
          col: 12,
          md: 4,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("createdAt"),
          value: "purchasedAt",
          col: 12,
          md: 6,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("situation"),
          value: "status",
          col: 12,
          md: 6,
          lg: 2,
          xl: 2,
        },
        {
          title: "",
          value: "action",
          col: 12,
          md: 6,
          lg: 3,
          xl: 3,
        },
      ],
    };
  },
  props: {
    event: {
      default: () => {},
    },
    badge: {
      default: () => {},
    },
    product: {
      default: () => {},
    },
    hideTitle: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    pages() {
      return Math.ceil(this.count / this.limit);
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
  },
  methods: {
    formatter,
    Currency,
    convertM2H,
    async GET_ALL_USER_EMAIL() {
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: {
          status: false,
          variant: "success",
          message: "",
        },
      });
      const response = await this.$api.get(
        `Products/ByProduct?table=event&productId=${this.event.id}&page=1&limit=${this.count}`
      );

      this.$store.commit("SET_LOADING_MODAL", {
        loading: false,
        alertBox: {
          status: false,
          variant: "success",
          message: "",
        },
      });
      if (response.message === "OK") {
        this.$store.commit("SET_MAIL_DRAWER", {
          status: true,
          form: {
            to: "",
            bcc: response.data.items
              .map((c) => c.email)
              .reduce(function (a, b) {
                if (a.indexOf(b) < 0) a.push(b);
                return a;
              }, [])
              .join(","),
            subject: this.$t("aboutBoughtProduct", {
              typeTitle: this.product.type.title,
              productTitle: this.product.title,
            }),
            content: "",
            files: [],
          },
        });
      }
      console.log(response);
    },
    SET_SEARCH(search) {
      this.search = search;
      this.GET_LIST();
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { ...this.$route.query, page } });
      this.GET_LIST();
    },
    async GET_LIST() {
      this.usersAreLoading = true;
      const response = await this.$api.get(
        `Products/ByProduct?table=event&productId=${this.event.id}&page=${this.page}&limit=${this.limit}&searchKey=${this.search}`
      );
      if (
        response.message === "OK" &&
        response.result === "OK" &&
        response.data !== null
      ) {
        this.count = response.data.count;
        this.userList = response.data.items;
      } else this.userList = [];

      this.usersAreLoading = false;
    },
    REMOVE_IF_SCHOOL_INSTRUCTOR() {
      if (
        this.getActiveUser.roleId === 3 &&
        this.getSettings.departmentId === 2
      )
        this.headers.splice(1, 1);
    },
  },
  created() {
    this.REMOVE_IF_SCHOOL_INSTRUCTOR();
  },
  mounted() {
    if (!isNaN(Number(this.$route.query.page)))
      this.page = Number(this.$route.query.page);
    this.GET_LIST();
  },
};
</script>
