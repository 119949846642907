<template>
  <section :class="{ category: editable }" class="channel-cover">
    <div v-if="title !== ''" class="banner-text">
      <h1>{{ title }}</h1>
    </div>
    <div class="px-5">
      <img
        v-if="imgSrc === ''"
        @error="
          (e) => {
            e.target.src = getSettings.defaultImage;
            e.target.style = 'width: 250px';
          }
        "
        :src="srcImage"
        width="250"
        alt=""
      />
    </div>
    <div
      class="editable-banner"
      @click="$refs['banner-file'].click()"
      v-if="editable"
    >
      <button class="btn btn-pill btn-success">
        <i class="icon-pencil"></i> {{ $t("toImage") }}
        {{ imgSrc !== "" ? $t("change") : $t("edit") }}
      </button>
      <input
        @change="SET_IMAGE"
        type="file"
        accept="image/*"
        class="d-none"
        ref="banner-file"
        name="banner-file"
        id="banner-file"
      />
    </div>
    <div v-if="imgSrc !== ''" class="crop-image-button">
      <button class="btn btn-pill btn-success" @click="CROP_IMAGE(400)">
        {{ $t("saveImage") }}
      </button>
    </div>
    <div class="row" v-show="imgSrc !== ''">
      <div class="col-12 col-lg-12">
        <img
          v-if="resultImage !== ''"
          class="w-100"
          @error="(e) => (e.target.src = '/android-chrome-384x384.png')"
          :src="resultImage"
          alt=""
        />
        <div v-else-if="imgSrc !== ''">
          <vue-cropper
            ref="cropper"
            :src="imgSrc"
            class="position-relative"
            alt="Source Image"
            :aspectRatio="1 / 1"
            :viewMode="1"
            dragMode="move"
            :cropBoxMovable="false"
            :center="true"
            :cropBoxResizable="false"
            :minCropBoxWidth="400"
            :minCropBoxHeight="400"
          >
          </vue-cropper>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ImageCropper from "../../mixins/ImageCropper";
export default {
  mixins: [ImageCropper],
  props: {
    title: {
      default: "",
    },
    src: {
      default: "/images/banner-img.jpeg",
    },
    image: {
      default: () => {
        return {
          uri: "",
          file: [],
        };
      },
    },
    editable: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(["getSettings"]),
    srcImage() {
      if (this.image.uri !== "") return this.image.uri;
      else return this.src;
    },
  },
  mounted() {
    this.sizes = { height: 218, width: 218 };
  },
};
</script>
